import React, { useState, useContext, useCallback, useEffect } from 'react'
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import { useQuery, ApolloProvider, useMutation } from '@apollo/react-hooks'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useParams,
  Redirect,
} from "react-router-dom"

import { ConfirmAction } from './components/ConfirmAction'
import { GraphQLUri } from './settings'

import 'animate.css'

import "../node_modules/bootstrap/js/src/collapse.js"

console.log(`GraphQLUri: ${GraphQLUri}`)

const LOCALSTORAGE_TOKEN_KEY = 'auth.token'

const client = new ApolloClient({
  uri: GraphQLUri,
  // @TODO see about disabling the cache or automatically
  //       refreshing the "Listings" when a new entity is
  //       inserted/deleted.
  request: (operation) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      }
    })
  },
});
client.defaultOptions = {
  errorPolicy: 'all'
}

const checkboxColWidth = '1.6rem'
const idColWidth = '3rem'

const NotifierContext = React.createContext({
  message: null,
  timeoutHandle: null,
  pushNotification: () => {},
})


const ALL_FILTERS_QUERY = gql`
  query EntityValueFilterSetsQuery {
    entityValueFilterSets {
      id
      name
      setOperator
      type {
        id
        name
      }
      entityvaluefilterSet {
        id
        key
        operator
        value
      }
    }
  }
`;


const ListEntityValueFilterSetsScreen = () => {
  const notifier = useContext(NotifierContext)
  const { loading, error, data, refetch } = useQuery(ALL_FILTERS_QUERY)

  const [ deleteFilter ] = useMutation(gql`
    mutation DeleteFilterMutation ( $id: ID! ) {
      deleteFilter ( id: $id ) {
        id
      }
    }
  `)

  if (loading) return 'loading...'
  if (error) return 'error...'

  const filters = data.entityValueFilterSets

  return (
    <div className="mt-3">
      <nav aria-label="breadcrumb" className="m-0 mt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">Filters</li>
        </ol>
      </nav>

      <div className="card">
        <div className="card-body">
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Operator</th>
                <th>Conditions</th>
                <th style={{ textAlign: 'right' }}>
                  <Link to="/filters/create">
                    + Create a new filter
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {filters.map((filter, i) => {
                return (
                  <tr key={i}>
                    <td>{filter.name}</td>
                    <td>{filter.type.name}</td>
                    <td>{filter.setOperator ? <>{filter.setOperator}</> : <strong>N/A</strong>}</td>
                    <td>
                      {filter.entityvaluefilterSet.length === 0 ? (
                        <strong>N/A</strong>
                      ): (
                        <>{filter.entityvaluefilterSet.length}</>
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Link to={`/filters/${filter.id}/edit`} className="badge badge-light">Modify</Link>
                      <ConfirmAction
                        linkText="Delete"
                        titleText="Confirmation required"
                        bodyText="Please confirm to continue. This action can not be undone!"
                        action={async () => {
                          await deleteFilter({variables:{id: filter.id}})
                          await refetch()
                          notifier.pushNotification({
                            type: 'primary',
                            text: 'Filter delted!',
                          })
                        }}
                        />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const SelectFilterByTypeComponent = ({ typeName, onUpdate, value }) => {
  const { data, loading, error } = useQuery(gql`
    query FilterByTypeQuery ( $typeName: String! ) {
      entityValueFilterSets(where:{
        typeName: $typeName
      }) {
        id
        name
      }
    }
  `, {
    variables: {
      typeName
    }
  })
  if (loading) return 'loading...'
  if (error) return 'error...'
  const filterElmId = generateElmId()
  return (
    <div className="form-group">
      <label htmlFor={filterElmId}>Select a {typeName} filter</label>
      <select id={filterElmId} className="form-control" value={value} onChange={e => {
        onUpdate(e.target.value)
      }}>
        <option value="">---</option>
        {data.entityValueFilterSets.map((evfs, i) => (
          <option key={i} value={evfs.id}>{evfs.name}</option>
        ))}
      </select>
    </div>
  )
}

// const copy = o => JSON.parse(JSON.stringify(o))

const TypeSelectorComponent = ({ onSelected }) => {
  const [selected, setSelected] = useState("")
  const { data, loading, error } = useQuery(gql`
    query {
      types {
        id
        name
      }
    }
  `)
  if (loading) return 'loading'
  if (error) return 'error'
  const elmId = generateElmId()
  return (
    <div className="form-group">
      <label htmlFor={elmId}>Select the data type</label>
      <select id={elmId} className="form-control" value={selected}
        onChange={e => {
          const type = data.types.filter(t => e.target.value === t.id)[0]
          setSelected(type.id)
          onSelected(type)
        }}>
        <option value="">---</option>
        {data.types.map((t, i) => (
          <option key={t.id} value={t.id}>{t.name}</option>
        ))}
      </select>
    </div>
  )
}

const CreateEntityValueFilterSetComponent = () => {
  const notifier = useContext(NotifierContext)
  const [type, setType] = useState(null)
  const [name, setName] = useState('')
  const [createEntityValueFilterSet, { data, error, loading }] = useMutation(gql`
    mutation CreateEntityValueFilterSet ($name: String!, $typeName: String!) {
      createEntityValueFilterSet(name: $name, typeName: $typeName) {
        id
        name
      }
    }
  `, {
    refetchQueries: [
      {
        query: ALL_FILTERS_QUERY,
      }
    ]
  })
  if (loading) return 'loading'
  if (error) return 'error'
  const nameElmId = generateElmId()
  if (data && data.createEntityValueFilterSet && data.createEntityValueFilterSet.id) {
    return <Redirect to={`/filters/${data.createEntityValueFilterSet.id}/edit`} />
  }
  return (
    <>
      <div className="form-group">
        <label htmlFor={nameElmId}>Give it a unique name</label>
        <input className="form-control"
          value={name}
          id={nameElmId}
          onChange={e => setName(e.target.value)}></input>
      </div>
      <TypeSelectorComponent onSelected={t => setType(t)} />
      <button className="btn btn-primary"
        disabled={!name || !type}
        onClick={async () => {
          await createEntityValueFilterSet({variables:{
            name,
            typeName: type.name,
          }})
          setName('')
          notifier.pushNotification({
            type: 'primary',
            text: 'Filter created!'
          })
        }}>
        Save
      </button>
    </>
  )
}


// const useElementId = () => {
//   const [elmId, setElmId] = useState(null)
//   useEffect(() => {
//     setElmId(`id-${Math.random().toString().split(".")[1]}`)
//   }, [])
//   return elmId
// }


const generateElmId = () => `id-${Math.random().toString().split(".")[1]}`


const CreateFilterKeySelectComponent = ({ filterId, onUpdate }) => {
  const { data, error, loading } = useQuery(gql`
    query EntityValueFilterSet ( $filterId: ID! ) {
      entityValueFilterSet ( id: $filterId ) {
        id
        name
        type {
          id
          name
          typeattributeSet {
            id
            name
          }
        }
      }
    }
  `, {
    variables: {
      filterId,
    }
  })
  if (error) return 'error'
  if (loading) return 'loading'
  const filter = data.entityValueFilterSet
  const elmId = generateElmId()
  return (
    <div className="form-group">
      <label htmlFor={elmId}>Attribute</label>
      <select id={elmId} className="form-control" onChange={e => {
        const attributeName = e.target.value
        onUpdate(attributeName)
      }}>
        <option value="">---</option>
        {filter.type.typeattributeSet.map((typeAttribute, i) => {
          return (
            <option key={i} value={typeAttribute.name}>
              {typeAttribute.name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

const AddSubFilterComponent = ({ filterId, subFilterChoices, onUpdate }) => {
  const [ addSubFilter ] = useMutation(gql`
    mutation AddSubFilterMutation ( $filterId: ID!, $subFilterId: ID! ) {
      addSubFilter ( filterId: $filterId, subFilterId: $subFilterId ) {
        id
      }
    }
  `)
  const [selectedId, setSelectedId] = useState("")
  const selectElmId = generateElmId()

  return (
    <>
      {subFilterChoices.length > 0 && (
        <div className="card mt-3">
          <div className="card-body">
            <h5 className="card-title">Reuse an existing filter</h5>

            <div className="form-group">
              <label htmlFor={selectElmId}>Select an existing filter</label>
              <select value={selectedId} id={selectElmId} className="form-control" onChange={e => {
                setSelectedId(e.target.value)
              }}>
                <option value="">---</option>
                {subFilterChoices.map((subFilter, i) => (
                  <option value={subFilter.id} key={i}>{subFilter.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <button className="btn btn-primary" disabled={!selectedId} onClick={async () => {
                setSelectedId("")
                await addSubFilter({variables:{
                  filterId,
                  subFilterId: selectedId,
                }})
                await onUpdate()
              }}>
                Reuse
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const AddFilterCondition = ({ filterId, onUpdate }) => {
  const notifier = useContext(NotifierContext)
  const [key, setKey] = useState('')
  const [operator, setOperator] = useState('')
  const [value, setValue] = useState('')
  const isValid = () => {
    return key && operator
  }

  const [createCondition, { error, loading }] = useMutation(gql`
    mutation CreateConditionMutation ( $filterId: ID!, $key: String!, $operator: String!, $value: String ) {
      createCondition ( filterId: $filterId, key: $key, operator: $operator, value: $value ) {
        id
        key
        value
        operator
      }
    }
  `)
  if (error) return 'error...'
  if (loading) return 'loading...'
  const operatorElmId = generateElmId()
  const valueElmId = generateElmId()
  return (
    <div className="mt-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Create a new condition</h5>

          <CreateFilterKeySelectComponent filterId={filterId} onUpdate={attributeName => {
            setKey(attributeName)
          }}/>

          <div className="form-group">
            <label htmlFor={operatorElmId}>Operator</label>
            <select className="form-control" id={operatorElmId} onChange={e => {
              setOperator(e.target.value)
            }}>
              <option value="">---</option>
              <option value="==">is equal to</option>
              <option value="!=">is different than</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor={valueElmId}>Compare value</label>
            <input className="form-control" id={valueElmId} onChange={e => {
              setValue(e.target.value)
            }}></input>
          </div>
          <button className="btn btn-primary"
            disabled={!isValid()}
            onClick={async () => {
              await createCondition({variables:{
                filterId,
                key,
                operator,
                value,
              }})
              setKey('')
              setOperator('')
              setValue('')
              onUpdate()
              notifier.pushNotification({
                type: 'primary',
                text: 'Condition created!',
              })
            }}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

const UpdateFilterScreen = () => {
  const notifier = useContext(NotifierContext)
  const { filterId } = useParams()
  const { loading, error, data, refetch } = useQuery(gql`
    query FilterQuery ( $id: ID! ) {
      entityValueFilterSet ( id: $id ) {
        id
        name
        setOperator
        entityvaluefilterSet {
          id
          key
          operator
          value
        }
        childrenSet {
          child {
            id
            name
          }
        }
      }
      subFilterChoices ( id: $id ) {
        id
        name
      }
    }
  `, {
    variables: {
      id: filterId,
    }
  })

  const [ updateFilter ] = useMutation(gql`
    mutation UpdateFilterMutation ( $filterId: ID!, $setOperator: String!, $name: String! ) {
      updateEntityValueFilterSet( id: $filterId, name: $name, setOperator: $setOperator ) {
        id
        name
        setOperator
      }
    }
  `)

  const [ deleteCondition ] = useMutation(gql`
    mutation DeleteConditionMutation ( $id: ID! ) {
      deleteCondition ( id: $id ) {
        key
        operator
        value
      }
    }
  `)

  const [ removeSubFilter ] = useMutation(gql`
    mutation RemoveSubFilterMutation ( $filterId: ID!, $subFilterId: ID! ) {
      removeSubFilter ( filterId: $filterId, subFilterId: $subFilterId ) {
        id
      }
    }
  `)

  if (loading) return 'loading...'
  if (error) return 'error...'

  const filter = data.entityValueFilterSet
  const { subFilterChoices } = data
  const conditions = filter.entityvaluefilterSet
  const childrenSet = filter.childrenSet

  return (
    <div className="mt-3">
      
      <nav aria-label="breadcrumb" className="m-0 mt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/filters">Filters</Link></li>
          <li className="breadcrumb-item"><Link to={`/filters/${filter.id}`}>{filter.id}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
      </nav>

      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="elm-filter-name">Name</label>
            <input className="form-control" id="elm-filter-name" value={filter.name} disabled={true} />
          </div>

          {(conditions.length === 0 && childrenSet.length === 0) && (
            <div className="card-text">
              Start customizing your filter by adding conditions or sub filters.
            </div>
          )}

          {(conditions.length + childrenSet.length) > 1 && (
            <div className="form-group">
              <label htmlFor="elm-condition-operator">How should we combine these filters?</label>
              <select className="form-control" id="elm-condition-operator" value={filter.setOperator} onChange={async e => {
                await updateFilter({variables:{
                  filterId,
                  name: filter.name,
                  setOperator: e.target.value,
                }})
                await refetch()
                notifier.pushNotification({
                  type: 'primary',
                  text: 'Filter updated!'
                })
              }}>
                <option value="AND">all conditions must be true</option>
                <option value="OR">at least one condition is true</option>
              </select>
            </div>
          )}

          {(childrenSet.length > 0 || conditions.length > 0) && (

          
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th>Condition</th>
                  <th style={{ textAlign: 'right' }}></th>
                </tr>
              </thead>
              <tbody>
                {conditions.map((condition, i) => (
                  <tr key={i}>
                    <td>
                      {condition.key} {condition.operator} {condition.value}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <ConfirmAction
                        linkText="Delete"
                        titleText="Confirmation required"
                        bodyText={`Please confirm to delete the condition: "${condition.key} ${condition.operator} ${condition.value}"`}
                        action={async () => {
                          await deleteCondition({variables:{id: condition.id}})
                          await refetch()
                          notifier.pushNotification({
                            type: 'primary',
                            text: 'Condition deleted!'
                          })
                        }}>
                      </ConfirmAction>
                    </td>
                  </tr>
                ))}

                {childrenSet.map((childFilterLink, i) => (
                  <tr key={i}>
                    <td className="text-muted">NA</td>
                    <td>
                      {childFilterLink.child.name}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <ConfirmAction
                        linkText="Remove"
                        titleText="Confirmation required"
                        bodyText="Please confirm to remove this sub filter"
                        action={async () => {
                          await removeSubFilter({variables:{
                            filterId,
                            subFilterId: childFilterLink.child.id,
                          }})
                          await refetch()
                        }}/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-sm">
          <AddFilterCondition filterId={filterId} onUpdate={async () => {
            await refetch()
            notifier.pushNotification({
              type: 'primary',
              text: 'Condition created!'
            })
          }} />
        </div>

        <div className="col-sm">
          <AddSubFilterComponent filterId={filterId} subFilterChoices={subFilterChoices} onUpdate={async () => {
            await refetch()
            notifier.pushNotification({
              type: 'primary',
              text: 'Sub filter added!',
            })
          }}/>
        </div>
      </div>

    </div>
  )
}


const ALL_EXTRACTS_QUERY = gql`
  query {
    extracts {
      id
      name
      type {
        id
        name
      }
    }
  }
`;


const ExtractsListingComponent = () => {
  const { data, error, loading, refetch } = useQuery(ALL_EXTRACTS_QUERY)

  const [deleteExtract] = useMutation(gql`
    mutation DeleteExtractMutation ($id: ID!) {
      deleteExtract ( id: $id ) {
        id
      }
    }
  `)

  const [executeExtract] = useMutation(gql`
    mutation ExecuteExtractMutation ($id: ID!) {
      executeExtract ( id: $id ) {
        outputFile
        outputFileUrl
      }
    }
  `)

  const [extractExecuting, setExtractExecuting] = useState(false)

  if (loading) return 'loading...'
  if (error) return 'error...'
  if (extractExecuting) return (
    <Loading />
  )
  return (
    <div className="mt-3">
      <nav aria-label="breadcrumb" className="m-0 mt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">Extracts</li>
        </ol>
      </nav>

      <div className="card">
        <div className="card-body">
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th style={{ textAlign: 'right'}}>
                  <Link to="/extracts/create">
                  + Create a new extract
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.extracts.map((extract, i) => (
                <tr key={i}>
                  <td>{extract.name}</td>
                  <td>{extract.type.name}</td>
                  <td style={{ textAlign: 'right' }}>
                    <a className="ml-3 badge badge-light" href="#/" onClick={async () => {
                      const { id } = extract
                      setExtractExecuting(true)
                      const result = await executeExtract({variables:{id}})
                      setExtractExecuting(false)
                      // window.alert(result.data.executeExtract.outputFileUrl)
                      window.open(result.data.executeExtract.outputFileUrl)
                    }}>
                      Execute
                    </a>
                    <Link to={`/extracts/${extract.id}/edit`} className="ml-2 badge badge-light">
                      Modify
                    </Link>
                    <ConfirmAction
                      linkText="Delete"
                      titleText="Confirmation required"
                      bodyText={`Please confirm`}
                      action={async () => {
                        await deleteExtract({variables:{id: extract.id}})
                        await refetch()
                      }}
                      />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const CreateFilterScreen = () => {
  return (
    <div className="mt-3">
      <nav aria-label="breadcrumb" className="m-0 mt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/filters">Filters</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Create</li>
        </ol>
      </nav>

      <div className="card mt-3">
        <div className="card-body">
          <h5 className="card-title">Create a new filter</h5>
          <CreateEntityValueFilterSetComponent />
        </div>
      </div>
    </div>
  )
}

const CreateAnExtractScreen = () => {
  const notifier = useContext(NotifierContext)
  const [ createExtract, { data, error, loading } ] = useMutation(gql`
    mutation CreateExtractMutation ( $name: String!, $typeId: ID! ) {
      createExtract( name: $name, typeId: $typeId ) {
        id
      }
    }
  `, {
    refetchQueries: [
      {
        query: ALL_EXTRACTS_QUERY,
      }
    ]
  })
  const [form, setForm] = useState({
    name: '',
    typeId: null,
  })
  if (error) return 'error...'
  if (loading) return 'loading...'
  const nameElmId = generateElmId()
  if (data && data.createExtract && data.createExtract.id) {
    return <Redirect to={`/extracts/${data.createExtract.id}/edit`} push={false} />
  }
  return (
    <div className="mt-3">
      <nav aria-label="breadcrumb" className="m-0 mt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/extracts">Extracts</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Create</li>
        </ol>
      </nav>

      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Create a new extract</h5>
          <div className="form-group">
            <label htmlFor={nameElmId}>Give it a unique name</label>
            <input className="form-control" value={form.name} id={nameElmId}
              onChange={e => setForm({...form, name: e.target.value})}
              />
          </div>
          <TypeSelectorComponent onSelected={type => setForm({...form, typeId: type.id})} />

          <div className="form-group">
            <button className="btn btn-primary" disabled={!(form.name && form.typeId)}
              onClick={() => {
                createExtract({variables:form})
                notifier.pushNotification({
                  type: 'primary',
                  text: 'New extract created'
                })
              }}>
              Save
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}

const ExtractTableComponent = ({ id }) => {
  const notifier = useContext(NotifierContext)

  const { data, loading, error, refetch } = useQuery(gql`
    query ExtractQuery ( $id: ID! ) {
      extract ( id: $id ) {
        id
        name
        children {
          id
          extract {
            id
          }
          typeRelation {
            id
            type
          }
          parent {
            id
          }
        }
        type {
          id
          name
          typeattributeSet {
            id
            name
            displayName
          }
          childrenTypes {
            id
            name
            type
		    		dstEntityType {
              id
              name
            }
          }
        }
        filter {
          id
          name
        }
        attributes {
          id
        }
      }
    }
  `, {
    variables: {
      id,
    }
  })

  const [ toggleExtractAttribute ] = useMutation(gql`
    mutation ToggleExtractAttribute ( $id: ID!, $attributeId: ID! ) {
      toggleExtractAttribute ( id: $id, attributeId: $attributeId ) {
        id
      }
    }
  `)

  const [ toggleExtractTypeRelation ] = useMutation(gql`
    mutation ToggleExtractTypeRelationMutation ( $id: ID!, $typeRelationId: ID! ) {
      toggleExtractTypeRelation ( id: $id, typeRelationId: $typeRelationId ) {
        id
      }
    }
  `)

  const [ updateExtractFilter ] = useMutation(gql`
    mutation UpdateExtractFilterMutation ( $id: ID!, $filterId: ID! ) {
      updateExtractFilter( id: $id, filterId: $filterId ) {
        id
      }
    }
  `)

  const [ removeExtractFilter ] = useMutation(gql`
    mutation RemoveExtractFilterMutation ( $id: ID! ) {
      removeExtractFilter( id: $id ) {
        id
      }
    }
  `)

  if (loading) return 'loading...'
  if (error) return 'error...'
  const { extract } = data
  const { filter } = extract
  const typeAttributeIsSelected = (searchAttr) => extract.attributes.filter(a => a.id === searchAttr.id).length > 0
  const relatedFieldSelected = (ct) => extract.children.filter(child => child.typeRelation.id === ct.id).length > 0
  return (
    <>
      <SelectFilterByTypeComponent value={((filter && filter.id) ? filter.id : "")} typeName={extract.type.name} onUpdate={async (filterId) => {
        if (filterId.length) {
          await updateExtractFilter({variables:{
            id: extract.id,
            filterId, 
          }})
        } else {
          await removeExtractFilter({variables:{ id: extract.id }})
        }
        await refetch()
        notifier.pushNotification({
          type: 'primary',
          text: 'Extract filter updated!',
        })
      }} />

      <table className="table table-sm m-0">
        <tbody>
          {extract.type.childrenTypes.filter(childType => true).map((childType, i) => (
            <tr key={i}>
              <td style={{ width: checkboxColWidth, textAlign: 'center' }}>
                <input type="checkbox"
                  readOnly={true}
                  checked={relatedFieldSelected(childType)}
                  onClick={async () => {
                    await toggleExtractTypeRelation({variables:{id: extract.id, typeRelationId: childType.id}})
                    await refetch()
                    notifier.pushNotification({
                        type: 'primary',
                        text: "Changes saved"
                    })
                  }}/>
              </td>
              <td>
                {childType.name} ({childType.type})
                {relatedFieldSelected(childType) && (() => {
                  const relatedExtractId = extract.children.filter(child => child.typeRelation.id === childType.id)[0].extract.id;
                  return <ExtractTableComponent id={relatedExtractId} />
                })()}
              </td>
            </tr>
          ))}

          {extract.type.typeattributeSet.map((attr, i) => (
            <tr key={i}
              onClick={async () => {
                await toggleExtractAttribute({variables:{id: extract.id, attributeId: attr.id}})
                await refetch()
                notifier.pushNotification({
                  type: 'primary',
                  text: 'Changes saved',
                })
              }}
              className={(typeAttributeIsSelected(attr) ? 'table-secondary' : '')}>
              <td style={{ width: checkboxColWidth, textAlign: 'center' }}>
                <input type="checkbox" checked={(typeAttributeIsSelected(attr))} readOnly={true} />
              </td>
              <td>{attr.name}</td>
            </tr>              
          ))}
        </tbody>
      </table>
    </>
  )
}

const EditAnExtractScreen = () => {
  const { id } = useParams()
  return (
    <>
      <nav aria-label="breadcrumb" className="m-0 mt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/extracts">Extracts</Link></li>
          <li className="breadcrumb-item"><a href="#/">{id}</a></li>
          <li className="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
      </nav>

      <div className="card">
        <div className="card-body">
          <ExtractTableComponent id={id} />
        </div>
      </div>
    </>
  )
}

const DashboardScreen = () => {
  const playgroundLink = () => {
    // strange url format expected, see here:
    // https://github.com/prisma-labs/graphql-playground/blob/824c7a57f0284f022726a8b8840aafc3e8720ccd/packages/graphql-playground-react/src/components/PlaygroundWrapper.tsx#L150
    const headers = encodeURIComponent(JSON.stringify({Authorization: `Bearer ${localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)}`}))
    return `/playground.html?endpoint=${GraphQLUri}?headers=${headers}`
  }
  return (
    <>
      <div className="jumbotron mt-3">
        <h1 className="display-4">Welcome!</h1>
        <p className="lead">This is a tool for filtering and extracting data collected from the AURORA study.</p>
        <hr className="my-4" />
        <p>We really need some documentation.</p>
        <p className="lead">
          <a className="btn btn-primary btn-lg" href="#/" role="button">Read the docs</a>
        </p>
      </div>
      <p>
        Open the <a target="_blank"
          rel="noopener noreferrer"
          href={playgroundLink()}
          >graphql playground</a>!
      </p>
    </>
  )
}


const MainScreen = () => {
  // const [collapse, setCollapse] = useState(false)  
  return (
    <Router>
      <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav mr-auto">
              {/**               <li className="nav-item animate__animated animate__bounce animate__infinite">  */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  <strong style={{ color: "white" }}>Dashboard</strong>
                </NavLink>
              </li>
              <li className="nav-item"><NavLink className="nav-link" to="/filters">Filters</NavLink></li>
              <li className="nav-item"><NavLink className="nav-link" to="/extracts">Extracts</NavLink></li>
            </ul>
            <ul className="navbar-nav">
              <li className="navbar-item">
                <a href={false} className="nav-link" onClick={() => {
                  localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, null);
                  window.location.href = "/";
                }}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </nav>
        
        <div className="container-fluid">
          <Switch>
            <Route path="/filters/create"><CreateFilterScreen /></Route>
            <Route path="/filters/:filterId/edit"><UpdateFilterScreen /></Route>
            <Route path="/filters"><ListEntityValueFilterSetsScreen /></Route>
            <Route path="/extracts/create"><CreateAnExtractScreen /></Route>
            <Route path="/extracts/:id/edit"><EditAnExtractScreen /></Route>
            <Route path="/extracts"><ExtractsListingComponent /></Route>
            <Route path="/profile/update-password"><UpdateMyPasswordScreen /></Route>
            <Route path="/"><DashboardScreen /></Route>
          </Switch>
        </div>
      </>
    </Router>
  )
}

const NotificationPopup = () => {
  const { message } = useContext(NotifierContext)
  if (!message) return null
  // const { type, text } = message
  const cssType = "success"
  // const cssType = type || "info"
  return (
    <>
      {message ? (
        <div style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
        }}
          className={`alert alert-${cssType} animate__animated animate__backInLeft`}>
          {/** <h5>{text || "OK"}</h5> */}
          <strong>Changes saved</strong>
        </div>
      ) : null }
    </>
  )
}

var timeout;

const NotifierProvider = ({ children }) => {
  const [message, setMessage] = useState(null)
  const pushNotification = (message) => {
    clearTimeout(timeout)
    setMessage(message)
    timeout = setTimeout(() => {
      setMessage(null)
    }, 3200)
  }
  const contextValue = {
    message,
    pushNotification: useCallback((message) => pushNotification(message), []),
  }
  return (
    <NotifierContext.Provider value={contextValue}>
      {children}
    </NotifierContext.Provider>
  )
}

/**
 * see https://tobiasahlin.com/spinkit/ for the details
 * css is in the index.html
 */
const Loading = () => {
  return (
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
  )
}


const getTimestamp = () => Math.round((new Date()).getTime() / 1000)
const getExpiresAt = expiresIn => getTimestamp() + expiresIn


const UpdateMyPasswordScreen = () => {
  const [redirect, setRedirect] = useState(null)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [updateMyPassword, { error, loading }] = useMutation(gql`
    mutation UpdateMyPasswordMutation ( $currentPassword: String!, $newPassword: String! ) {
      updateMyPassword( currentPassword: $currentPassword, newPassword: $newPassword ) {
        id
        email
      }
    }
  `)
  const isValid = () => (
    newPassword === confirmNewPassword &&
      newPassword.length >= 7 &&
      currentPassword &&
      newPassword !== currentPassword
  )
  if (loading) return 'loading...'
  if (redirect) {
    return <Redirect to="/" />
  }
  return (
    <div className="card mt-3">
      <div className="card-body">
        <div className="form-group">
          <label>Your current password</label>
          <input type="password" className="form-control"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)} />
        </div>
        <hr />
        <div className="form-group">
          <label>Your new password</label>
          <input type="password" className="form-control"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Re-enter your new password</label>
          <input type="password" className="form-control"
            value={confirmNewPassword}
            onChange={e => setConfirmNewPassword(e.target.value)} />
        </div>
        {error && (
          <div className="card bg-warning text-white mb-3">
            <div className="card-body">
              {error.message}
              {/**
              <pre>{JSON.stringify(error, null, 2)}</pre>
              */}
            </div>
          </div>
        )}
        <button className="btn btn-primary"
          disabled={!isValid()}
          onClick={async () => {
            try {
              var response = await updateMyPassword({variables:{
                currentPassword,
                newPassword,
              }})
              setRedirect(true)
            } catch {
              console.log(response)
            }
          }}>
          Update Password
        </button>
      </div>
    </div>
  )
}

const AuthenticationRequred = ({ children }) => {
  const [tokenAuth] = useMutation(gql`
    mutation TokenAuthMutation ( $username: String!, $password: String! ) {
      tokenAuth ( username: $username, password: $password ) {
        token
        payload
        refreshExpiresIn
      }
    }
  `)
  const [verifyToken] = useMutation(gql`
    mutation VerifyToken ( $token: String! ) {
      verifyToken(token: $token) {
        payload
      }
    }
  `)
  const [refreshToken] = useMutation(gql`
    mutation RefreshTokenMutation ( $token: String! ) {
      refreshToken ( token: $token ) {
        payload
        refreshExpiresIn
        token
      }
    }
  `)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showLoginForm, setShowLoginForm] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      (async function loop() {
        let refreshResponse
        try {
          refreshResponse = await refreshToken({variables: {token: localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)}})
          localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, refreshResponse.data.refreshToken.token)
          console.log("token refreshed!")
        } catch (e) {
          localStorage.clear()
          setShowLoginForm(true)
          setInitialLoading(false)
        }
      })()
    }, 1000 * 90) // 1.5 minutes
    return () => clearInterval(interval)
  }, [refreshToken, verifyToken])

  // if there is a token present on the user:
  //     if the token is valid:
  //         get+save a new token (RefreshTokenMutation)
  //     else:
  //         show login screen
  // else:
  //     show login screen

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)
    if (token) {
      console.log("token found...");
      (async function useEffectInner() {
        let verifyResponse

        try {
          verifyResponse = await verifyToken({variables:{token}})
        } catch (e) {
          setShowLoginForm(true)
          setInitialLoading(false)
          localStorage.clear()
          return
        }

        const { errors } = verifyResponse
        if (errors && errors.length > 0) {
          setShowLoginForm(true)
          setInitialLoading(false)
          localStorage.clear()
          return
        }

        const refreshResponse = await refreshToken({variables:{token}})
        if (refreshResponse.errors) {
          throw new Error(refreshResponse.errors)
        }

        localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, refreshResponse.data.refreshToken.token)
        localStorage.setItem('auth.expiresAt', getExpiresAt(refreshResponse.data.refreshToken.refreshExpiresIn))
        setShowLoginForm(false)
        setInitialLoading(false)
      })()
    } else {
      setShowLoginForm(true)
      setInitialLoading(false)
    }
  }, [verifyToken, refreshToken])

  if (initialLoading) return <Loading />
  if (!showLoginForm) return children

  return (
    <div className="container">
      <div className="card mt-5">
        <div className="card-body">

          <div className="form-group">
            <input type="text"
              className="form-control"
              placeholder="Username"
              value={username}
              onChange={e => {
                setUsername(e.target.value)
              }}/>
          </div>

          <div className="form-group">
            <input
              className="form-control"
              placeholder="Password"
              type="password"
              value={password}
              onChange={e => {
                setPassword(e.target.value)
              }}/>
          </div>

          <button className="btn btn-primary"
            onClick={() => {
              setInitialLoading(true)
              tokenAuth({variables:{
                username,
                password,
              }}).then(response => {
                setInitialLoading(false)
                setShowLoginForm(false)
                const { token } = response.data.tokenAuth
                localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token)

              }).catch(reason => {
                setInitialLoading(false)
                setShowLoginForm(true)
              })
            }}>Authenticate</button>

        </div>
      </div>
    </div>
  )
}

const App = () => {
  return (
    <NotifierProvider>
      <ApolloProvider client={client}>
        <AuthenticationRequred>
          <MainScreen />
        </AuthenticationRequred>
        <NotificationPopup />
      </ApolloProvider>
    </NotifierProvider>
  );
}

export default App