import React, { useState } from 'react'
import {
  Button,
  Modal,
} from "react-bootstrap"


export const ConfirmAction = ({ action, linkText, titleText, bodyText }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (show) {
    return (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={async () => {
            handleClose()
            await action()
          }}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <a href="#/" className="badge badge-light ml-2" onClick={() => {
      handleShow()
    }}>
      {linkText}
    </a>
  )
}